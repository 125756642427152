/*::-webkit-scrollbar {*/
/*    display:none;*/
/*}*/

* {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0px;
}

*::-webkit-scrollbar-track {
    background: orange;
}

*::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
}

.myButton {
    height: 30px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    vertical-align: center;
    padding: 5px;
}

.myButton span {
    height: 100%;
}
.myButton:hover {
    border-color: red;
    cursor: pointer;
}

